
a.meanmenu-reveal {
	display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  float: left;
  position: relative;
  width: 100%;
  z-index: 99;
}

.mean-container a.meanmenu-reveal {
  border: 1px solid #444;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1px;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  margin-top: -40px;
  padding: 8px 9px;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
  width: 22px;
}
.mean-container a.meanmenu-reveal span {
  background: #444 none repeat scroll 0 0;
  display: block;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  background: #0c1923 none repeat scroll 0 0;
  float: left;
  margin-top: 16px;
  width: 100%;
}

.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}

.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  display: block;
  float: left;
  margin: 0;
  padding: 10px 5%;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  width: 90%;
  font-size:13px;
}

.mean-container .mean-nav ul li li a {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  opacity: 0.75;
  padding: 10px 10%;
  text-shadow: none;
  visibility: visible;
  width: 80%;
}

.mean-container .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255,255,255,0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  border-color: currentcolor currentcolor rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.4);
  border-image: none;
  border-style: none none solid solid;
  border-width: medium medium 1px 1px;
  font-weight: 700;
  height: 24px;
  padding: 8px 12px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 26px;
  z-index: 2;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
	background: rgba(0,0,0,0.9);
}

.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}

.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}


.mean-remove {
	display: none !important;
}

/* custom css */
.mobile-menu.mean-container {
	width: 100%;
}
.mean-bar::before {
	position: absolute;
	content: "\f00a";
	font-family: "font awesome 5 free";
	font-weight: 600;
	top: -50px;
	right: 5px;
	font-size: 34px;
  color: #fc8459; /*icon color */
  display: none;
}
.mean-container a.meanmenu-reveal span {
	height: 0;
}
.mean-container a.meanmenu-reveal {
  border: 0;
}
.mean-container a.meanmenu-reveal.meanclose,.mean-container a.meanmenu-reveal {  
  background:  transparent;
  width: 0;
}
.mean-container .mean-nav ul li a {
  border-bottom: none;
  border-bottom: 1px solid #e1e1e1;
  color: #222;
  background: #fff;
  padding: 13px 5%;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
}
.mean-container .mean-nav ul li a:hover {
  color: #222;
  background: #fff;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
	background: #fff
}
.mean-container a.meanmenu-reveal {
	margin-top: -250px;

}