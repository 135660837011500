/* Deafult Margin & Padding */
/*-- Margin Top --*/
.mt-5 {
    margin-top: 5px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-25 {
    margin-top: 25px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-35 {
    margin-top: 35px;
  }
  .mt-40 {
    margin-top: 40px;
  }
  .mt-45 {
    margin-top: 45px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mt-55 {
    margin-top: 55px;
  }
  .mt-60 {
    margin-top: 60px;
  }
  .mt-65 {
    margin-top: 65px;
  }
  .mt-70 {
    margin-top: 70px;
  }
  .mt-75 {
    margin-top: 75px;
  }
  .mt-80 {
    margin-top: 80px;
  }
  .mt-85 {
    margin-top: 85px;
  }
  .mt-90 {
    margin-top: 90px;
  }
  .mt-95 {
    margin-top: 95px;
  }
  .mt-100 {
    margin-top: 100px;
  }
  .mt-105 {
    margin-top: 105px;
  }
  .mt-110 {
    margin-top: 110px;
  }
  .mt-115 {
    margin-top: 115px;
  }
  .mt-120 {
    margin-top: 120px;
  }
  .mt-125 {
    margin-top: 125px;
  }
  .mt-130 {
    margin-top: 130px;
  }
  .mt-135 {
    margin-top: 135px;
  }
  .mt-140 {
    margin-top: 140px;
  }
  .mt-145 {
    margin-top: 145px;
  }
  .mt-150 {
    margin-top: 150px;
  }
  .mt-155 {
    margin-top: 155px;
  }
  .mt-160 {
    margin-top: 160px;
  }
  .mt-165 {
    margin-top: 165px;
  }
  .mt-170 {
    margin-top: 170px;
  }
  .mt-175 {
    margin-top: 175px;
  }
  .mt-180 {
    margin-top: 180px;
  }
  .mt-185 {
    margin-top: 185px;
  }
  .mt-190 {
    margin-top: 190px;
  }
  .mt-195 {
    margin-top: 195px;
  }
  .mt-200 {
    margin-top: 200px;
  }
  /*-- Margin Bottom --*/
  
  .mb-5 {
    margin-bottom: 5px;
  }
  .mb-6 {
    margin-bottom: 6px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-22 {
    margin-bottom: 22px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .mb-25 {
    margin-bottom: 25px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-32 {
    margin-bottom: 32px;
  }
  .mb-35 {
    margin-bottom: 35px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-45 {
    margin-bottom: 45px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .mb-55 {
    margin-bottom: 55px;
  }
  .mb-60 {
    margin-bottom: 60px;
  }
  .mb-65 {
    margin-bottom: 65px;
  }
  .mb-70 {
    margin-bottom: 70px;
  }
  .mb-75 {
    margin-bottom: 75px;
  }
  .mb-80 {
    margin-bottom: 80px;
  }
  .mb-85 {
    margin-bottom: 85px;
  }
  .mb-90 {
    margin-bottom: 90px;
  }
  .mb-95 {
    margin-bottom: 95px;
  }
  .mb-100 {
    margin-bottom: 100px;
  }
  .mb-105 {
    margin-bottom: 105px;
  }
  .mb-110 {
    margin-bottom: 110px;
  }
  .mb-115 {
    margin-bottom: 115px;
  }
  .mb-120 {
    margin-bottom: 120px;
  }
  .mb-125 {
    margin-bottom: 125px;
  }
  .mb-130 {
    margin-bottom: 130px;
  }
  .mb-135 {
    margin-bottom: 135px;
  }
  .mb-140 {
    margin-bottom: 140px;
  }
  .mb-145 {
    margin-bottom: 145px;
  }
  .mb-150 {
    margin-bottom: 150px;
  }
  .mb-155 {
    margin-bottom: 155px;
  }
  .mb-160 {
    margin-bottom: 160px;
  }
  .mb-165 {
    margin-bottom: 165px;
  }
  .mb-170 {
    margin-bottom: 170px;
  }
  .mb-175 {
    margin-bottom: 175px;
  }
  .mb-180 {
    margin-bottom: 180px;
  }
  .mb-185 {
    margin-bottom: 185px;
  }
  .mb-190 {
    margin-bottom: 190px;
  }
  .mb-195 {
    margin-bottom: 195px;
  }
  .mb-200 {
    margin-bottom: 200px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .mr-15 {
    margin-right: 15px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .mr-30 {
    margin-right: 30px;
  }
  .mr-100 {
    margin-right: 100px;
  }
  .mr-120 {
    margin-right: 120px;
  }
  .mr-150 {
    margin-right: 180px;
  }
  .mr-200 {
    margin-right: 200px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .mr--30 {
    margin-right: -30px;
  }
  .mr--55 {
    margin-right: -55px;
  }
  
  .ml-20 {
    margin-left: 20px;
  }
  .ml-30 {
    margin-left: 30px;
  }
  .ml-25 {
    margin-left: 28px;
  }
  .ml-100 {
    margin-left: 100px;
  }
  .ml-130 {
    margin-left: 130px;
  }
  .ml-150 {
    margin-left: 150px;
  }
  .ml-200 {
    margin-left: 200px;
  }
  .ml-300 {
    margin-left: 300px;
  }
  .ml-200 {
    margin-left: 200px;
  }
  .mt--20 {
    margin-top: -20px;
  }
  .mt--8 {
    margin-top: -8px;
  }
  .mt--30 {
    margin-top: -30px;
  }
  .mt--45 {
    margin-top: -45px;
  }
  .ml-6 {
    margin-left: 6px;
  }
  .mr-6 {
    margin-right: 6px;
  }
  /*-- Padding Top --*/
  
  .pt-5 {
    padding-top: 5px;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .pt-15 {
    padding-top: 15px;
  }
  .pt-20 {
    padding-top: 20px;
  }
  .pt-25 {
    padding-top: 25px;
  }
  .pt-30 {
    padding-top: 30px;
  }
  .pt-32 {
    padding-top: 32px;
  }
  .pt-35 {
    padding-top: 35px;
  }
  .pt-40 {
    padding-top: 40px;
  }
  .pt-45 {
    padding-top: 45px;
  }
  .pt-50 {
    padding-top: 50px;
  }
  .pt-55 {
    padding-top: 55px;
  }
  .pt-60 {
    padding-top: 60px;
  }
  .pt-65 {
    padding-top: 65px;
  }
  .pt-70 {
    padding-top: 70px;
  }
  .pt-75 {
    padding-top: 75px;
  }
  .pt-80 {
    padding-top: 80px;
  }
  .pt-85 {
    padding-top: 85px;
  }
  .pt-90 {
    padding-top: 90px;
  }
  .pt-95 {
    padding-top: 95px;
  }
  .pt-100 {
    padding-top: 100px;
  }
  .pt-105 {
    padding-top: 105px;
  }
  .pt-110 {
    padding-top: 110px;
  }
  .pt-115 {
    padding-top: 115px;
  }
  .pt-120 {
    padding-top: 120px;
  }
  .pt-125 {
    padding-top: 125px;
  }
  .pt-130 {
    padding-top: 130px;
  }
  .pt-135 {
    padding-top: 135px;
  }
  .pt-140 {
    padding-top: 140px;
  }
  .pt-145 {
    padding-top: 145px;
  }
  .pt-150 {
    padding-top: 150px;
  }
  .pt-155 {
    padding-top: 155px;
  }
  .pt-160 {
    padding-top: 160px;
  }
  .pt-165 {
    padding-top: 165px;
  }
  .pt-170 {
    padding-top: 170px;
  }
  .pt-175 {
    padding-top: 175px;
  }
  .pt-180 {
    padding-top: 180px;
  }
  .pt-185 {
    padding-top: 185px;
  }
  .pt-190 {
    padding-top: 190px;
  }
  .pt-195 {
    padding-top: 195px;
  }
  .pt-200 {
    padding-top: 200px;
  }
  .pt-300 {
    padding-top: 300px;
  }
  .pt-400 {
    padding-top: 400px;
  }
  /*-- Padding Bottom --*/
  
  .pb-6 {
    padding-bottom: 5px;
  }
  .pb-10 {
    padding-bottom: 10px;
  }
  .pb-15 {
    padding-bottom: 15px;
  }
  .pb-20 {
    padding-bottom: 20px;
  }
  .pb-25 {
    padding-bottom: 25px;
  }
  .pb-30 {
    padding-bottom: 30px;
  }
  .pb-35 {
    padding-bottom: 35px;
  }
  .pb-40 {
    padding-bottom: 40px;
  }
  .pb-45 {
    padding-bottom: 45px;
  }
  .pb-50 {
    padding-bottom: 50px;
  }
  .pb-55 {
    padding-bottom: 55px;
  }
  .pb-60 {
    padding-bottom: 60px;
  }
  .pb-65 {
    padding-bottom: 65px;
  }
  .pb-70 {
    padding-bottom: 70px;
  }
  .pb-75 {
    padding-bottom: 75px;
  }
  .pb-80 {
    padding-bottom: 80px;
  }
  .pb-85 {
    padding-bottom: 85px;
  }
  .pb-90 {
    padding-bottom: 90px;
  }
  .pb-95 {
    padding-bottom: 95px;
  }
  .pb-100 {
    padding-bottom: 100px;
  }
  .pb-105 {
    padding-bottom: 105px;
  }
  .pb-110 {
    padding-bottom: 110px;
  }
  .pb-115 {
    padding-bottom: 115px;
  }
  .pb-120 {
    padding-bottom: 120px;
  }
  .pb-125 {
    padding-bottom: 125px;
  }
  .pb-130 {
    padding-bottom: 130px;
  }
  .pb-135 {
    padding-bottom: 135px;
  }
  .pb-140 {
    padding-bottom: 140px;
  }
  .pb-145 {
    padding-bottom: 145px;
  }
  .pb-148 {
    padding-bottom: 148px;
  }
  .pb-150 {
    padding-bottom: 150px;
  }
  .pb-155 {
    padding-bottom: 155px;
  }
  .pb-160 {
    padding-bottom: 160px;
  }
  .pb-165 {
    padding-bottom: 165px;
  }
  .pb-170 {
    padding-bottom: 170px;
  }
  .pb-175 {
    padding-bottom: 175px;
  }
  .pb-180 {
    padding-bottom: 180px;
  }
  .pb-185 {
    padding-bottom: 185px;
  }
  .pb-190 {
    padding-bottom: 190px;
  }
  .pb-195 {
    padding-bottom: 195px;
  }
  .pb-200 {
    padding-bottom: 200px;
  }
  
  /*-- Padding Left --*/
  .pl-0 {
    padding-left: 0px;
  }
  .pl5 {
    padding-left: 5px;
  }
  .pl-10 {
    padding-left: 10px;
  }
  .pl-12 {
    padding-left: 12px;
  }
  .pl-15 {
    padding-left: 15px;
  }
  .pl-20 {
    padding-left: 20px;
  }
  .pl-25 {
    padding-left: 25px;
  }
  .pl-30 {
    padding-left: 30px;
  }
  .pl-35 {
    padding-left: 35px;
  }
  
  .pl-35 {
    padding-left: 35px;
  }
  
  .pl-40 {
    padding-left: 40px;
  }
  .pl-42 {
    padding-left: 42px;
  }
  .pl-45 {
    padding-left: 45px;
  }
  
  .pl-50 {
    padding-left: 50px;
  }
  
  .pl-55 {
    padding-left: 55px;
  }
  
  .pl-60 {
    padding-left: 60px;
  }
  .pl-65 {
    padding-left: 65px;
  }
  .pl-70 {
    padding-left: 70px;
  }
  .pl-75 {
    padding-left: 75px;
  }
  .pl-80 {
    padding-left: 80px;
  }
  .pl-85 {
    padding-left: 80px;
  }
  .pl-90 {
    padding-left: 90px;
  }
  .pl-95 {
    padding-left: 95px;
  }
  .pl-100 {
    padding-left: 100px;
  }
  .pl-140 {
    padding-left: 140px;
  }
  .pl-230 {
    padding-left: 230px;
  }
  
  /*-- Padding Right --*/
  .pr-0 {
    padding-right: 0px;
  }
  .pr5 {
    padding-right: 5px;
  }
  .pr-10 {
    padding-right: 10px;
  }
  .pr-12 {
    padding-right: 12px;
  }
  .pr-15 {
    padding-right: 15px;
  }
  .pr-20 {
    padding-right: 20px;
  }
  .pr-25 {
    padding-right: 25px;
  }
  .pr-30 {
    padding-right: 30px;
  }
  .pr-35 {
    padding-right: 35px;
  }
  
  .pr-35 {
    padding-right: 35px;
  }
  
  .pr-40 {
    padding-right: 40px;
  }
  
  .pr-45 {
    padding-right: 45px;
  }
  
  .pr-50 {
    padding-right: 50px;
  }
  
  .pr-55 {
    padding-right: 55px;
  }
  
  .pr-60 {
    padding-right: 60px;
  }
  .pr-65 {
    padding-right: 65px;
  }
  .pr-70 {
    padding-right: 70px;
  }
  .pr-75 {
    padding-right: 75px;
  }
  .pr-80 {
    padding-right: 80px;
  }
  .pr-85 {
    padding-right: 80px;
  }
  .pr-90 {
    padding-right: 90px;
  }
  .pr-95 {
    padding-right: 95px;
  }
  .pr-100 {
    padding-right: 100px;
  }
  .pr-150 {
    padding-right: 140px;
  }
  .pr-200 {
    padding-right: 200px;
  }
  .pr-230 {
    padding-right: 230px;
  }
  .pl-120 {
    padding-left: 120px;
  }
  .pr-120 {
    padding-right: 120px;
  }
  .ml--40 {
    margin-left: -35px;
  }
  .pb-260 {
    padding-bottom: 260px;
  }
  .mt--50 {
    margin-top: -40px;
  }
  .pl3 {
    padding-left: 3px;
  }
  .pr3 {
    padding-right: 3px;
  }
  .pl6 {
    padding-left: 6px;
  }
  .pr6 {
    padding-right: 6px;
  }
  .mt--5 {
    margin-top: -5px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .mb-28 {
    margin-bottom: 28px;
  }
  /* Background Color */
  .theme-bg {
    background: var(--main-color);
  }
  .primary-bg {
    background: var(--primary-color);
  }
  .section-bg {
    background: var(--section-bg);
  }
  .secondary-bg {
    background: #f5f4f4;
  }
  .white-bg {
    background: #fff;
  }
  .dark-bg {
    background: #2f3036;
  }
  .form-bg {
    background: #26272e;
  }
  .dark-black-bg {
    background: #000000 !important;
  }
  .gray-bg {
    background: #d4d4d4;
  }
  .gray-bg2 {
    background: #f7f7f7;
  }
  .light-gray-bg {
    background: #f5f5f5;
  }
  .orange-bg {
    background: #da1b1b;
  }
  .pink-bg {
    background: #f06eaa;
  }
  .lower-blue-bg {
    background: #8194d9;
  }
  .darkblue-bg {
    background: #004a80;
  }
  .yellow-brown-bg {
    background: #c69c6d;
  }
  .green-bg {
    background: #1cbbb4;
  }
  .yellow-bg {
    background: #fbaf5d;
  }
  .dark-black {
    background: #000;
  }
  .footer-4-bg {
    background-color: #161619;
  }
  .p-blue {
    background: #2349d5;
    border: 1px solid #2349d5 !important;
  }
  .p-d-blue {
    background: #004a80;
    border: 1px solid #004a80 !important;
  }
  .p-gray {
    background: #d4d4d4;
    border: 1px solid #d4d4d4 !important;
  }
  .p-green {
    background: #1cbbb4;
    border: 1px solid #1cbbb4 !important;
  }
  .p-l-black {
    background: #363636;
    border: 1px solid #363636 !important;
  }
  .p-l-blue {
    background: #8194d9;
    border: 1px solid #8194d9 !important;
  }
  .p-orange {
    background: #fbaf5d;
    border: 1px solid #fbaf5d !important;
  }
  .p-pink {
    background: #f06eaa;
    border: 1px solid #f06eaa !important;
  }
  .p-yellow {
    background: #c69c6d;
    border: 1px solid #c69c6d !important;
  }
  
  /* Color */
  .theme-color {
    color: var(--main-color);
  }
  .text-color {
    color: var(--text-color);
  }
  .meta-text-color {
    color: var(--meta-text-color);
  }
  .primary-color {
    color: var(--primary-color);
  }
  .secondary-color {
    color: var(secondary-color);
  }
  .secondary-color2 {
    color: #4d4d5c;
  }
  .secondary-color3 {
    color: #646d8a;
  }
  .white-text {
    color: #fff;
  }
  .dark-black-color {
    color: #000000;
  }
  .black-color {
    color: #222222;
  }
  /* border css */
  .main-border {
    border: 1px solid var(--main-border);
  }
  .theme-border-top {
    border-top: 1px solid #474747;
  }
  .theme-border-bottom {
    border-bottom: 1px solid #474747;
  }
  .theme-border {
    border: 1px solid #474747;
  }
  .white-border {
    border: 1px solid #ffffff;
  }
  .border-radius10 {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .border-radius12 {
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
  .border-radius13 {
    border-radius: 13px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    -ms-border-radius: 13px;
    -o-border-radius: 13px;
  }
  
  /* social link-color css */
  .facebook-bg {
    background: #1877f2;
  }
  .twitter-bg {
    background: #1da1f2;
  }
  .instagram-bg {
    background: #405de6;
  }
  .linkedin-bg {
    background: #0077b5;
  }
  .facebook-color {
    color: #1877f2;
  }
  .twitter-color {
    color: #1da1f2;
  }
  .instagram-color {
    color: #405de6;
  }
  .linkedin-color {
    color: #0077b5;
  }
  
  /* form contact css start */
  .form-color {
    color: #9b9db1;
  }
  .form-control:focus,
  .form-control:active {
    background-color: #f3f5fc;
    border: 1px solid #e6e9f7 !important;
    box-shadow: none;
  }
  input:focus-visible,
  textarea:focus-visible {
    border: 1px solid var(--main-color) !important;
    box-shadow: none;
    outline: 0;
  }
  *::-moz-placeholder {
    color: #9b9db1;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    opacity: 1;
  }
  *::placeholder {
    color: #9b9db1;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    opacity: 1;
  }
  /* form contact css end */
  
  /* common css start */
  /* font css */
  .primary-font-family {
    font-family: "jostMedium-font-family";
  }
  .flat-family {
    font-family: "Flaticon";
  }
  .f-500 {
    font-weight: 500;
  }
  .f-600 {
    font-weight: 600;
  }
  .f-800 {
    font-weight: 800;
  }
  .f-700 {
    font-weight: 700;
  }
  .f-900 {
    font-weight: 900;
  }
  /* others css */
  .top-0 {
    top: 0;
  }
  .left-0 {
    left: 0;
  }
  .right-0 {
    right: 0;
  }
  .bottom-0 {
    bottom: 0;
  }
  .over-hidden {
    overflow: hidden;
  }
  .clear-both {
    clear: both;
  }
  .over-scroll {
    overflow: scroll;
  }
  .over-x-hidden {
    overflow-x: hidden;
  }
  .over-x-scroll {
    overflow-y: scroll;
  }
  .over-y-hidden {
    overflow-x: hidden;
  }
  .z-index-1 {
    z-index: -1;
  }
  .z-index1 {
    z-index: 1;
  }
  .z-index11 {
    z-index: 11;
  }
  .border-radius5 {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .border-radius10 {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .border-radius12 {
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .primary-hover:hover {
    color: #ffffff;
  }
  .transition3 {
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  .transition5 {
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .img {
    transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  }
  .no-repeat {
    background-repeat: no-repeat;
  }
  .bg-cover {
    background-size: cover;
  }
  /* common css end */
  
  /* black overlay */
  [data-overlay] {
    position: relative;
  }
  [data-overlay]::before {
    background: #fc8459 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  [data-overlay="3"]::before {
    opacity: 0.3;
  }
  [data-overlay="4"]::before {
    opacity: 0.4;
  }
  [data-overlay="5"]::before {
    opacity: 0.5;
  }
  [data-overlay="6"]::before {
    opacity: 0.6;
  }
  [data-overlay="7"]::before {
    opacity: 0.7;
  }
  [data-overlay="8"]::before {
    opacity: 0.8;
  }
  [data-overlay="9"]::before {
    opacity: 0.9;
  }
  
  /* bounce-animate start */
  .bounce-animate {
    animation-name: float-bob;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 3s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
  }
  
  @-webkit-keyframes float-bob {
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }
    50% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }
  }
  /* bounce-animate css end */
  
  /* pulse btn css start */
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    70% {
      -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    70% {
      -moz-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  /* pulse btn css end */
  
  /* zoom-animation */
  .zoom-animation {
    animation: zoom-animation 4s infinite alternate;
    -webkit-animation: zoom-animation 4s infinite alternate;
  }
  /* heartbeat-middle */
  @-webkit-keyframes zoom-animation {
    0% {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
    }
    100% {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
    }
  }
  
  @-webkit-keyframes heartbeat2 {
    to {
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
    }
  }
  
  /* bounce-animate end*/
  
  /* preloader css start */
  #loading {
    background-color: #26272e;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999;
    margin-top: 0px;
    top: 0px;
  }
  #loading-center {
    width: 100%;
    height: 100%;
    position: relative;
  }
  #loading-center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 150px;
    width: 150px;
    margin-top: -75px;
    margin-left: -75px;
  }
  .object {
    width: 20px;
    height: 20px;
    background-color: var(--main-color);
    float: left;
    margin-right: 20px;
    margin-top: 65px;
    -moz-border-radius: 50% 50% 50% 50%;
    -webkit-border-radius: 50% 50% 50% 50%;
    border-radius: 50% 50% 50% 50%;
  }
  
  #object_one {
    -webkit-animation: object_one 1.5s infinite;
    animation: object_one 1.5s infinite;
  }
  #object_two {
    -webkit-animation: object_two 1.5s infinite;
    animation: object_two 1.5s infinite;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  #object_three {
    -webkit-animation: object_three 1.5s infinite;
    animation: object_three 1.5s infinite;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  
  @-webkit-keyframes object_one {
    75% {
      -webkit-transform: scale(0);
    }
  }
  
  @keyframes object_one {
    75% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }
  
  @-webkit-keyframes object_two {
    75% {
      -webkit-transform: scale(0);
    }
  }
  
  @keyframes object_two {
    75% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }
  
  @-webkit-keyframes object_three {
    75% {
      -webkit-transform: scale(0);
    }
  }
  
  @keyframes object_three {
    75% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }
  
  /* preloader end */
  
  /* arrow-bounce-animate start */
  .bounce-animate-arrow {
    animation-name: float-bob3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob3;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob3;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob3;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
  }
  
  @-webkit-keyframes float-bob3 {
    0% {
      -webkit-transform: translateY(-40px);
      transform: translateY(-40px);
      -moz-transform: translateY(-40px);
      -ms-transform: translateY(-40px);
      -o-transform: translateY(-40px);
    }
    50% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
    }
    100% {
      -webkit-transform: translateY(-40px);
      transform: translateY(-40px);
      -moz-transform: translateY(-40px);
      -ms-transform: translateY(-40px);
      -o-transform: translateY(-40px);
    }
  }
  /* bounce-animate end*/
  
  /* shape-up-down-animation start */
  .shape-up-down-animation {
    animation: anim-up-down 5s infinite;
    -webkit-animation: anim-up-down 5s infinite;
  }
  @keyframes anim-up-down {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(30px);
    }
    100% {
      transform: translateY(0);
    }
  }
  /* shape-up-down-animation end */